import { translateFunc } from "client/utils/translateFunc";
import { Translate } from "components/common/Translate/Translate";
import type { ImageUpload } from "store/features/imageUploader/types";

interface UploadProgressListHeaderProps {
    images: ImageUpload[];
    handleCancelAllImports: () => void;
}

export const UploadProgressListHeader = ({ images, handleCancelAllImports }: UploadProgressListHeaderProps) => {
    return (
        <div className="flex items-center justify-between text-black dark:text-white">
            <div className="flex items-center gap-4">
                <div className="font-semibold capitalize">
                    {images.length > 1 ? translateFunc("your-images") : translateFunc("your-image")}
                </div>
                {images.length > 1 && (
                    <div
                        className="text-irGray-600 dark:text-darkSurface-600 underline underline-offset-2 cursor-pointer capitalize"
                        onClick={handleCancelAllImports}
                    >
                        <Translate keyName="remove-all" /> ({images.length})
                    </div>
                )}
            </div>
        </div>
    );
};

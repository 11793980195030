import type { Task } from "@freeconvert/freeconvert-node/dist/types";
import { capitalize } from "client/utils/capitalize";
import { translateFunc } from "client/utils/translateFunc";
import { truncateImageTitle } from "client/utils/truncateImageTitle";
import { CloseIcon } from "components/common/Icons/CloseIcon";
import { DeleteIcon } from "components/common/Icons/DeleteIcon";
import { LandscapeIcon } from "components/common/Icons/LandscapeIcon";
import { ToolTip } from "components/common/ToolTip/ToolTip";
import { DeleteImage } from "components/modules/Uploader/useUploader";
import type { ImageUpload } from "store/features/imageUploader/types";

interface UploadProgressProps {
    image: ImageUpload;
    handleImportCancel: (task: Task) => void;
    deleteImage: DeleteImage;
    skipDeviceImport?: boolean;
}

export const UploadProgress = ({ image, handleImportCancel, deleteImage, skipDeviceImport }: UploadProgressProps) => {
    const {
        file,
        fileState,
        task,
        upload: { type },
    } = image;

    const shouldHideCancelAndDeleteIcon = type === "device" && skipDeviceImport;

    return (
        <>
            {/* mobile version */}
            <div className="lg:hidden flex items-center bg-white dark:bg-darkSurface-100 dark:text-white rounded-md py-4 px-2 gap-4">
                <div className="flex-grow flex flex-col gap-2">
                    <div className="flex justify-between">
                        {file && (
                            <div className="flex items-center gap-2">
                                <div className="font-semibold whitespace-nowrap">
                                    <div className="hidden md:block">
                                        {truncateImageTitle(file.file.name ?? "", 30)}
                                    </div>
                                    <div className="hidden sm:block md:hidden">
                                        {truncateImageTitle(file.file.name ?? "", 20)}
                                    </div>
                                    <div className="sm:hidden">{truncateImageTitle(file.file.name ?? "", 10)}</div>
                                </div>
                                <div className="whitespace-nowrap">{file.size}</div>
                            </div>
                        )}
                    </div>
                    <div className="relative bg-irGray-200 dark:bg-darkSurface-600 w-full h-2 rounded-l-full rounded-r-full overflow-hidden">
                        {fileState.progress === "continuous" ? (
                            <div className="absolute top-0 bottom-0 left-0 bg-irGreen-400 rounded-l-full rounded-r-full w-24 transition-all animate-progress"></div>
                        ) : (
                            <div
                                className="absolute top-0 bottom-0 left-0 bg-irGreen-400 rounded-l-full rounded-r-full normal-case"
                                style={{ width: `${fileState.progress}%` }}
                            ></div>
                        )}
                    </div>
                    <div className="capitalize">
                        {fileState.status === "importing" && (
                            <div data-testid="fileStatus-uploading" id="fileStatus-importing">
                                {translateFunc("common:uploading")}{" "}
                                {`${fileState.progress !== "continuous" ? `${fileState.progress}%` : ""}`}
                                <span className="normal-case">{fileState.speed && `( ${fileState.speed} / s )`}</span>
                            </div>
                        )}
                        {fileState.status === "imported" && (
                            <div id="fileStatus-imported">{`${translateFunc("uploaded")} ${fileState.progress}%`}</div>
                        )}
                        {fileState.status === "adding" && <div className="">{translateFunc("common:uploading")}</div>}
                    </div>
                </div>
                <div className="flex-grow-0">
                    {fileState.status !== "imported" &&
                        fileState.status !== "importing" &&
                        !shouldHideCancelAndDeleteIcon && (
                            <div className="flex items-center">
                                <div className="cursor-pointer" onClick={() => deleteImage(image)}>
                                    <CloseIcon width={8} height={8} />
                                </div>
                            </div>
                        )}
                    {(fileState.status !== "imported" || fileState.status.includes("creating_task")) &&
                        task &&
                        !shouldHideCancelAndDeleteIcon && (
                            <div className="flex items-center">
                                <div className="cursor-pointer" onClick={() => handleImportCancel(task)}>
                                    <CloseIcon width={8} height={8} />
                                </div>
                            </div>
                        )}
                    {fileState.status == "imported" && task && !shouldHideCancelAndDeleteIcon && (
                        <div className="flex items-center">
                            <div className="cursor-pointer" onClick={() => deleteImage(image)}>
                                <DeleteIcon />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* desktop version */}
            <div
                className="hidden lg:grid gap-4 bg-white dark:bg-darkSurface-100 p-4 rounded-md dark:text-white"
                style={{ gridTemplateColumns: "fit-content(100%) auto fit-content(100%)" }}
                data-testid={`file-${image.file.file.name}-container`}
            >
                <div className="hidden lg:block">
                    <LandscapeIcon />
                </div>
                <div className="w-full flex flex-col justify-between gap-2 my-1">
                    <div className="flex justify-between">
                        {file && (
                            <div className="flex items-center gap-2">
                                <ToolTip text={file.file.name ?? ""}>
                                    <div className="font-semibold select-text">
                                        {truncateImageTitle(`${file.file.name}` ?? "", 20)}
                                    </div>
                                </ToolTip>
                                <div className="">{file.size}</div>
                            </div>
                        )}
                        <div className="capitalize">
                            {fileState.status === "importing" && (
                                <div data-testid={`file-${image.file.file.name}-importing`} id="fileStatus-importing">
                                    {translateFunc("common:uploading")}{" "}
                                    {`${fileState.progress !== "continuous" ? `${fileState.progress}%` : ""}`}
                                    <span className="normal-case">
                                        {fileState.speed && `( ${fileState.speed} / s )`}
                                    </span>
                                </div>
                            )}
                            {fileState.status === "imported" && (
                                <div
                                    data-testid={`file-${image.file.file.name}-imported`}
                                    id="fileStatus-imported"
                                >{`${translateFunc("uploaded")} ${fileState.progress}%`}</div>
                            )}
                            {fileState.status === "adding" && (
                                <div className="">{translateFunc("common:uploading")}</div>
                            )}
                        </div>
                    </div>
                    <div className="relative bg-irGray-200 dark:darkSurface-600 w-full h-2 rounded-l-full rounded-r-full overflow-hidden">
                        {fileState.progress === "continuous" && fileState.status !== "added" ? (
                            <div className="absolute top-0 bottom-0 left-0 bg-irGreen-400 rounded-l-full rounded-r-full w-24 transition-all animate-progress" />
                        ) : (
                            <div
                                className="absolute top-0 bottom-0 left-0 bg-irGreen-400 rounded-l-full rounded-r-full normal-case"
                                style={{ width: `${fileState.progress}%` }}
                            />
                        )}
                    </div>
                </div>

                {fileState.status !== "imported" &&
                    fileState.status !== "importing" &&
                    !shouldHideCancelAndDeleteIcon && (
                        <div className="flex items-center">
                            <ToolTip
                                text={capitalize(translateFunc("cancel-upload"))}
                                position={{
                                    y: "up",
                                }}
                            >
                                <div className="group cursor-pointer" onClick={() => deleteImage(image)}>
                                    <div className="group-hover:hidden">
                                        <CloseIcon width={12} height={12} />
                                    </div>
                                    <div className="hidden group-hover:block">
                                        <CloseIcon width={12} height={12} className="stroke-[var(--red-500)]" />
                                    </div>
                                </div>
                            </ToolTip>
                        </div>
                    )}

                {(fileState.status == "importing" || fileState.status.includes("creating_task")) &&
                    task &&
                    !shouldHideCancelAndDeleteIcon && (
                        <div className="flex items-center">
                            <ToolTip
                                text={capitalize(translateFunc("cancel-upload"))}
                                position={{
                                    y: "up",
                                }}
                            >
                                <div className="group cursor-pointer" onClick={() => handleImportCancel(task)}>
                                    <div className="group-hover:hidden">
                                        <CloseIcon width={12} height={12} />
                                    </div>
                                    <div className="hidden group-hover:block">
                                        <CloseIcon width={12} height={12} className="stroke-[var(--red-500)]" />
                                    </div>
                                </div>
                            </ToolTip>
                        </div>
                    )}
                {fileState.status == "imported" && task && !shouldHideCancelAndDeleteIcon && (
                    <div className="flex items-center">
                        <ToolTip
                            text={capitalize(translateFunc("delete-image"))}
                            position={{
                                y: "up",
                            }}
                        >
                            <div className="group cursor-pointer" onClick={() => deleteImage(image)}>
                                <DeleteIcon />
                            </div>
                        </ToolTip>
                    </div>
                )}
            </div>
        </>
    );
};

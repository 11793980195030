import { useUrlUpload } from "client/hooks/upload/useUrlUpload";
import { HandleFile } from "client/types/HandleFile";
import { capitalize } from "client/utils/capitalize";
import { translateFunc } from "client/utils/translateFunc";
import { ArrowIcon } from "components/common/Icons/ArrowIcon";
import { Translate } from "components/common/Translate/Translate";
import { UrlInput } from "components/common/UrlInput/UrlInput";
import { useDispatch } from "react-redux";
import { setUploadType } from "store/features/imageUploader/actions";

interface UrlUploadProps {
    handleFile: HandleFile;
    supportedExtensions: string[];
}

export const UrlUpload = ({ handleFile, supportedExtensions }: UrlUploadProps) => {
    const { url, handleChange, handleSubmit, error, handleKeyPress, isValidating } = useUrlUpload(
        handleFile,
        () => {},
        {
            supportedExtensions,
        },
    );
    const dispatch = useDispatch();

    const handleBack = () => {
        dispatch(setUploadType({ uploadType: "device" }));
    };

    return (
        <div className="flex flex-col text-white py-8 gap-6" data-testid="url-upload-container">
            {/* back */}
            <div className="flex items-center cursor-pointer" onClick={handleBack}>
                <div className="rotate-90 mr-2">
                    <ArrowIcon className="stroke-white" height={15} width={15} />
                </div>
                <div className="font-semibold text-xl capitalize">
                    <Translate keyName="back" />
                </div>
            </div>
            <div className="flex flex-col gap-2">
                <UrlInput
                    url={url}
                    handleChange={handleChange}
                    handleKeyPress={handleKeyPress}
                    handleSubmit={handleSubmit}
                    isValidating={isValidating}
                />
                {error && (
                    <div className="text-xl font-semibold text-red-700" data-testid="url-upload-input-error">
                        {capitalize(translateFunc("error"))}: {error}
                    </div>
                )}
            </div>
        </div>
    );
};

import { FC, ReactNode } from "react";

interface UploadParentProps {
    children: ReactNode;
}

export const UploaderParent: FC<UploadParentProps> = ({ children }: UploadParentProps) => {
    return (
        <div className="flex items-center justify-center bg-irBlue-100 dark:bg-darkPrimary-600 p-3">
            {children}
        </div>
    );
};

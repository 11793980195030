import { DropBoxIcon } from "components/common/Icons/DropBoxIcon";
import { useDropBoxUpload } from "client/hooks/upload/useDropBoxUpload";
import { HandleFile } from "client/types/HandleFile";
import { Translate } from "components/common/Translate/Translate";
import { Loader } from "components/common/Icons/Loader/Loader";

interface DropBoxUploadProps {
    handleClick: () => void;
    handleFile: HandleFile;
    allowMultiple: boolean;
    supportedExtensions: string[];
}

export const DropBoxUpload = ({ handleClick, handleFile, allowMultiple, supportedExtensions }: DropBoxUploadProps) => {
    const { handleClick: handleUploadClick, isLoading } = useDropBoxUpload(
        handleFile,
        {
            allowMultiple,
            supportedExtensions,
        },
        true,
    );

    return (
        <li
            className="hover:bg-slate-200 dark:hover:bg-darkSurface-300 flex items-center gap-4 px-6 py-4"
            data-testid="dropbox-upload"
            onClick={() => {
                handleClick();
                handleUploadClick();
            }}
        >
            <div>
                {isLoading ? (
                    <Loader className="stroke-[var(--dark-gray)]" width={19.63} height={16.58} />
                ) : (
                    <DropBoxIcon width={19.63} height={16.58} />
                )}
            </div>
            <Translate keyName="from-dropbox" />
        </li>
    );
};

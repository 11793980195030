import { Task } from "@freeconvert/freeconvert-node/dist/types";
import { DeleteImage } from "components/modules/Uploader/useUploader";
import { UploadProgress } from "./UploadProgress/UploadProgress";
import { UploadProgressListHeader } from "./UploadProgressListHeader/UploadProgressListHeader";
import { useUploadProgressList } from "./useUploadProgressList";

export interface UploadProgressListProps {
    handleImportCancel: (task: Task) => void;
    handleCancelAllImports: () => void;
    deleteImage: DeleteImage;
    skipDeviceImport?: boolean;
}

export const UploadProgressList = ({
    handleImportCancel,
    deleteImage,
    handleCancelAllImports,
    skipDeviceImport,
}: UploadProgressListProps) => {
    const { images } = useUploadProgressList();
    if (Object.keys(images).length === 0) return null;
    return (
        <div className="my-2 py-6 px-4 w-auto bg-irBlue-100 dark:bg-darkSurface-200 flex flex-col rounded-md gap-6">
            <UploadProgressListHeader images={images} handleCancelAllImports={handleCancelAllImports} />

            <div className="flex flex-col gap-1">
                {Object.values(images).map((image) => {
                    return (
                        <UploadProgress
                            deleteImage={deleteImage}
                            key={image.id}
                            image={image}
                            handleImportCancel={handleImportCancel}
                            skipDeviceImport={skipDeviceImport}
                        />
                    );
                })}
            </div>
        </div>
    );
};

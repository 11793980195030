import { useOnOutsideClick } from "client/hooks/utils/useOnOutsideClick";
import { ga } from "client/utils/adsense/ga";
import { gtag } from "client/utils/adsense/gtag";
import { getSocket } from "client/utils/getSocket";
import { useState, useRef } from "react";
import { setUploadType } from "store/features/imageUploader/actions";
import { UploadType } from "store/features/imageUploader/types";
import { useAppDispatch } from "store/store";
import { ImageUploaderProps } from "../ImageUploader/ImageUploader";

export const useUploadTypeSelector = (analytics: ImageUploaderProps["analytics"]) => {
    const [isFocused, setIsFocused] = useState(false);
    const toggleFocus = () => setIsFocused(!isFocused);

    // for closing the dropdown on outside click
    const ref = useRef<HTMLDivElement>(null);
    useOnOutsideClick<HTMLDivElement>(ref, isFocused, toggleFocus);

    const dispatch = useAppDispatch();

    const handleUploadTypeChange = (uploadType: UploadType, skipToggle?: boolean) => {
        dispatch(setUploadType({ uploadType }));
        handleImageSelectedAnalytics(analytics);
        !skipToggle && toggleFocus();

        // pre connecting the socket for a better user experience
        getSocket();
    };

    return {
        isFocused,
        ref,
        toggleFocus,
        handleUploadTypeChange,
    };
};

export const handleImageSelectedAnalytics = (analytics: ImageUploaderProps["analytics"]) => {
    ga("send", {
        hitType: "pageview",
        page: `/vp/${analytics.pageKey}/image_selected/`,
        title: `${analytics.pageTitle} Image Selected`,
    });

    gtag("event", "Select Image", {
        event_category: analytics.pageTitle,
        event_label: "Upload",
    });
};

export const handleImageLoadedAnalytics = (analytics: ImageUploaderProps["analytics"]) => {
    ga("send", {
        hitType: "pageview",
        page: `/vp/${analytics.pageKey}/image_loaded/`,
        title: `${analytics.pageTitle} Image Loaded`,
    });

    gtag("event", "Image Loaded", {
        event_category: analytics.pageTitle,
        event_label: "Editor Page",
    });
};

import { GoogleDriveIcon } from "components/common/Icons/GoogleDriveIcon";
import { Translate } from "components/common/Translate/Translate";
import { Loader } from "components/common/Icons/Loader/Loader";

interface GoogleDriveUploadProps {
    handleClick: () => void;
    isLoading: boolean;
}

export const GoogleDriveUpload = ({ handleClick, isLoading }: GoogleDriveUploadProps) => {
    return (
        <li
            className="hover:bg-slate-200 dark:hover:bg-darkSurface-300 flex items-center gap-4 px-6 py-4"
            onClick={() => {
                handleClick();
            }}
        >
            <div>
                {isLoading ? (
                    <Loader width={17.48} height={16} />
                ) : (
                    <GoogleDriveIcon width={17.48} height={16} />
                )}
            </div>
            <Translate keyName="from-google-drive" />
        </li>
    );
};

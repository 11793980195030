import { DeviceIcon } from "components/common/Icons/DeviceIcon";
import { useDeviceUpload } from "client/hooks/upload/useDeviceUpload";
import { HandleFile } from "client/types/HandleFile";
import { Translate } from "components/common/Translate/Translate";

interface DeviceUploadProps {
    handleClick: () => void;
    handleFile: HandleFile;
    htmlFileInputId: string;
    supportedExtensions: string[];
    allowMultiple?: boolean;
}

export const DeviceUpload = ({
    handleClick,
    handleFile,
    allowMultiple,
    htmlFileInputId,
    supportedExtensions,
}: DeviceUploadProps) => {
    const { handleClick: handleInputClick } = useDeviceUpload(
        handleFile,
        {
            allowMultiple: !!allowMultiple,
            supportedExtensions,
        },
        htmlFileInputId,
    );

    return (
        <li
            className="hover:bg-slate-200 dark:hover:bg-darkSurface-300 flex items-center gap-4 px-6 py-4"
            data-testid="device-upload"
            onClick={() => {
                handleClick();
                handleInputClick();
            }}
            id="uploader-device-upload"
        >
            <div>
                <DeviceIcon width={15.8} height={17.9} />
            </div>
            <div className="capitalize">
                <Translate keyName="from-device" />
            </div>
        </li>
    );
};

import { imageUploaderSelector } from "store/features/imageUploader/selectors/imageUploaderSelector";
import { useAppDispatch, useAppSelector } from "store/store";
import { hideInvalidImageExtensionModal } from "store/features/imageUploader/actions";

export const useInvalidImageExtensionModal = () => {
    const {
        invalidImageExtensionModal: { isOpen, invalidExts, allowedExts },
    } = useAppSelector(imageUploaderSelector);
    const dispatch = useAppDispatch();

    const closeModal = () => dispatch(hideInvalidImageExtensionModal());

    return { isOpen, closeModal, allowedExts, invalidExts };
};

import { imageUploaderSelector } from "store/features/imageUploader/selectors/imageUploaderSelector";
import { FileState, ImageUpload, ImageUploaderState } from "store/features/imageUploader/types";
import { useAppSelector } from "store/store";

export const useUploadProgressList = () => {
    const { images: _images } = useAppSelector(imageUploaderSelector, equalityFunc);
    const images = getImages(_images);

    return { images };
};

const equalityFunc = (left: ImageUploaderState, right: ImageUploaderState) => {
    if (JSON.stringify(left.images) !== JSON.stringify(right.images)) return false;

    return true;
};

const getImages = (images: ImageUpload[]): ImageUpload[] => {
    const allowedStatuses: FileState["status"][] = [
        "added",
        "creating_task",
        "creating_task_failed",
        "importing",
        "importing_failed",
        "imported",
    ];

    return images.filter((image) => allowedStatuses.includes(image.fileState.status));
};
